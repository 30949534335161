import http from '@/http';

export const getCompetitions = async (params) => {
    return await http.get('competition', params) || [];
}

export const getCompetition = async (id) => {
    return await http.get(`competition/${id}`);
}

export const createCompetition = async (payload) => {
    return await http.post('competition', payload);
}

export const updateCompetition = async (id, payload) => {
    payload.append('_method', 'put');
    return await http.post(`competition/${id}`, payload);
}

export const updateCompetitionJudges = async (id, payload) => {
    return await http.post(`competition/judges/${id}`, { ...payload, _method: 'put' });
}

export const updateCompetitionParticipants = async (id, payload) => {
    return await http.post(`competition/participants/${id}`, { ...payload, _method: 'put' });
}

export const updateCompetitionParticipantsFromXls = async (id, payload) => {
    payload.append('_method', 'put');
    return await http.post(`competition/participants/xls/${id}`, payload);
}

export const updateCompetitionRounds = async (id, payload) => {
    return await http.post(`competition/rounds/${id}`, { ...payload, _method: 'put' });
}

export const deleteCompetition = async (id) => {
    return await http.post(`competition/${id}`, { _method: 'delete' });
}

export const downloadCompetitionStartListUrl = (id, from, to = '') => {
    return `${process.env.VUE_APP_API_URL}/competition/start-list/${id}/${from}${to ? `/${to}` : ''}`;
}

export const downloadCompetitionReverseStartListUrl = (id, round = '') => {
    return `${process.env.VUE_APP_API_URL}/competition/reverse-start-list/${id}/${round}`;
}

export const downloadCompetitionRecordListUrl = (id, from, to = '') => {
    return `${process.env.VUE_APP_API_URL}/competition/record-list/${id}/${from}${to ? `/${to}` : ''}`;
}

export const downloadCompetitionReverseRecordListUrl = (id, round = '') => {
    return `${process.env.VUE_APP_API_URL}/competition/reverse-record-list/${id}/${round}`;
}

export const downloadCompetitionCivlcompsUrl = (id) => {
    return `${process.env.VUE_APP_API_URL}/competition/civlcomps/${id}`;
}

export const downloadCompetitionReportsUrl = (id, round = '') => {
    return `${process.env.VUE_APP_API_URL}/competition/reports/${id}${round ? `/${round}` : ''}`;
}

export const getCompetitionAdmins = async (id) => {
    return await http.get(`competition/admins/${id}`);
}

export const updateCompetitionAdmins = async (id, payload) => {
    return await http.put(`competition/admins/${id}`, { ...payload, _method: 'put' });
}

export const getTieBreak = async (competitionId, tieBreakId) => {
    return await http.get(`competition/tiebreak/${competitionId}/${tieBreakId}`);
}

export const createTieBreak = async (competitionId, payload) => {
    return await http.post(`competition/tiebreak/${competitionId}`, payload);
}

export const updateTieBreakRounds = async (competitionId, tieBreakId, payload) => {
    return await http.post(`competition/tiebreak/rounds/${competitionId}/${tieBreakId}`, { ...payload, _method: 'put' });
}

export const getGeneralCivlData = async (contestId, contestToken) => {
    return await http.get(`competition/general-civl-data?contestId=${contestId}&contestToken=${contestToken}`);
}

export const getParticipantsCivlData = async (contestId, contestToken) => {
    return await http.get(`competition/participants-civl-data?contestId=${contestId}&contestToken=${contestToken}`);
}